import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchItemSalesData } from "../../../Redux/features/Reports/ItemSales/ItemSalesSlice";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import { Grid } from "@mui/material";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { priceFormate } from "../../../hooks/priceFormate";
import { SkeletonTable } from "../../../reuseableComponents/SkeletonTable";
import sortIcon from "../../../Assests/Category/SortingW.svg";
import { SortTableItemsHelperFun } from "../../../helperFunctions/SortTableItemsHelperFun";
import PasswordShow from "../../../Common/passwordShow";
import NoDataFound from "../../../reuseableComponents/NoDataFound";
import useDelayedNodata from "../../../hooks/useDelayedNoData";
import { formatCurrency } from "../../../Constants/utils";

const StyledTable = styled(Table)(({ theme }) => ({
  padding: 2, // Adjust padding as needed
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#253338",
    color: theme.palette.common.white,
    fontFamily: "CircularSTDMedium !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
  [`&.${tableCellClasses.table}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {},
  "& td, & th": {
    border: "none",
  },
}));

const ItemSalesDetails = (props) => {
  const dispatch = useDispatch();
  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  const [sortOrder, setSortOrder] = useState("asc");
  const [allItemSalesData, setallItemSalesData] = useState([]);
  const showNoData = useDelayedNodata(allItemSalesData);
  const [apiStatus, setapiStatus] = useState(false);
  const AllItemSalesDataState = useSelector(
    (state) => state.ItemSalesReportList
  );
  let merchant_id = LoginGetDashBoardRecordJson?.data?.merchant_id;
  useEffect(() => {
    getFetchItemSalesData();
    // console.log("All Props",props)
  }, [
    props.selectedDateRange,
    props.OrderSourceData,
    props.OrderTypeData,
    props.SelectCatData,
    props.items,
    props.brand,
    props.tag,
    props.selectedCategoryList,
    props.selectedBrandList,
    props.selectedTagList,
  ]);
  // console.log("props.selectedTagList,",props.selectedTagList)
  // console.log("props.allTagSelected,,",props.allTagSelected)
  const getFetchItemSalesData = async () => {
    if (props && props.selectedDateRange) {
      try {
        let data = {
          merchant_id,
          start_date: props.selectedDateRange.start_date,
          end_date: props.selectedDateRange.end_date,
          order_typ: props.OrderTypeData,
          order_env: props.OrderSourceData,
          // search_by_brand: props.brand === "All" ? "" : props.brand,
          // search_by_tag: props.tag === "All" ? "" : props.tag,
          // cat_name:
          //   Array.isArray(props.SelectCatData) &&
          //   props.SelectCatData.length > 0 &&
          //   !props.allOptionSelected
          //     ? props.SelectCatData.map((emp) => emp.title).toString()
          //     : "All",
          cat_name:
            Array.isArray(props.selectedCategoryList) &&
            props.selectedCategoryList.length > 0 &&
            !props.allCategorySelected
              ? props.selectedCategoryList
                  .map((cat) => `"${cat.title}"`)
                  .join(",")
              : "All",
          // search_by_brand:
          //   Array.isArray(props.selectedBrandList) &&
          //   props.selectedBrandList.length > 0 &&
          //   !props.allBrandSelected
          //     ? props.selectedBrandList
          //         .map((brand) => `${brand.title}`)
          //         .join(",")
          //     : props.selectedBrandList
          //         .map((brand) => `${brand.title}`)
          //         .join(","),
          // search_by_tag:
          //   Array.isArray(props.selectedTagList) &&
          //   props.selectedTagList.length > 0 &&
          //   !props.allTagSelected
          //     ? props.selectedTagList.map((tag) => `${tag.title}`).join(",")
          //     : props.selectedTagList.map((tag) => `${tag.title}`).join(","),
          // search_by: props.items,
          search_by_brand: props?.selectedBrandList
            ?.map((brand) => `${brand?.title}`)
            ?.join(","),
          search_by_tag: props?.selectedTagList
            ?.map((tag) => `${tag?.title}`)
            ?.join(","),
          search_by: props.items,
          ...userTypeData,
        };
        if (data) {
          await dispatch(fetchItemSalesData(data)).unwrap();
        }
      } catch (error) {
        if (error.status == 401) {
          getUnAutherisedTokenMessage();
          handleCoockieExpire();
        }
      }
    }
  };

  useEffect(() => {
    if (
      !AllItemSalesDataState.loading &&
      AllItemSalesDataState.ItemSalesData &&
      AllItemSalesDataState.ItemSalesData[0]
    ) {
      setallItemSalesData(AllItemSalesDataState.ItemSalesData[0]);
      setapiStatus(AllItemSalesDataState.ItemSalesData[3]);

      const csvData = AllItemSalesDataState.ItemSalesData[0];

      if (csvData.length > 0) {
        const data = csvData.map((item) => ({
          ...item,
          total_price: !!item?.total_price
            ? `${priceFormate(formatCurrency(item?.total_price))}`
            : "$0.00",

          // `$${priceFormate(
          //   parseFloat(item?.total_price).toFixed(2)
          // )}`,
          adjust_price: !!item?.adjust_price
            ? `${priceFormate(formatCurrency(item?.adjust_price))}`
            : "$0.00",

          // `$${priceFormate(
          //   parseFloat(item?.adjust_price).toFixed(2)
          // )}`,
          discount_amt: `$${priceFormate(
            parseFloat(item?.discount_amt).toFixed(2)
          )}`,
          saletx: `$${priceFormate(parseFloat(item?.saletx))}`,
          othertx: `$${priceFormate(parseFloat(item?.othertx))}`,
          refund_amount: `$${priceFormate(
            parseFloat(item?.refund_amount).toFixed(2)
          )}`,
          discount_price: !!item?.discount_price
            ? `${priceFormate(formatCurrency(item?.discount_price))}`
            : "$0.00",

          //  `$${priceFormate(
          //   parseFloat(item?.discount_price).toFixed(2)
          // )}`,
        }));
        console.log("data: ", data);

        props.setCSVData(data);
        props.setCSVHeader(
          tableRow.map((row) => ({ label: row.label, key: row.name }))
        );
        props.setCSVFileName("items_report");
      }
    } else {
      props.setCSVHeader([]);
      setallItemSalesData([]);
      props.setCSVFileName("");

      // setapiStatus(false);
      !AllItemSalesDataState?.ItemSalesData && setapiStatus(false);
    }
  }, [AllItemSalesDataState, AllItemSalesDataState.ItemSalesData]);
  const tableRow = [
    { type: "str", name: "name", label: "Name" },
    { type: "str", name: "categoryss", label: "Category" },
    { type: "num", name: "total_qty", label: "# Sold" },
    { type: "num", name: "total_price", label: "Gross Sales" },
    { type: "num", name: "adjust_price", label: "Price Override" },
    { type: "num", name: "discount_amt", label: "Discounts" },
    { type: "num", name: "saletx", label: "Default Tax" },
    { type: "num", name: "othertx", label: "Other Tax" },
    { type: "num", name: "refund_amount", label: "Refunded" },
    { type: "num", name: "discount_price", label: "Net Sales" },
  ];
  const sortByItemName = (type, name) => {
    const { sortedItems, newOrder } = SortTableItemsHelperFun(
      allItemSalesData,
      type,
      name,
      sortOrder
    );
    setallItemSalesData(sortedItems);
    setSortOrder(newOrder);
  };

  return (
    <>
      <Grid container className="box_shadow_div">
        <Grid item xs={12}>
          {/* <div className="q-attributes-bottom-header">
            <span>Item Sales Report</span>
          </div> */}
          {AllItemSalesDataState.loading ||
          (apiStatus && !allItemSalesData.length) ? (
            <SkeletonTable columns={tableRow.map((item) => item.label)} />
          ) : (
            <TableContainer
              className="custom-scroll-hidden"
              sx={{ maxHeight: "80vh" }}
            >
              <StyledTable
                stickyHeader
                sx={{ minWidth: 500 }}
                aria-label="customized table"
              >
                <TableHead>
                  {tableRow.map((item) => (
                    <StyledTableCell>
                      <button
                        className="flex items-center"
                        onClick={() => sortByItemName(item.type, item.name)}
                      >
                        <p>{item.label}</p>
                        <img src={sortIcon} alt="" className="pl-1" />
                      </button>
                    </StyledTableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {allItemSalesData &&
                    allItemSalesData.length >= 1 &&
                    allItemSalesData.map((ItemData, index) => (
                      <StyledTableRow>
                        <StyledTableCell>
                          <p>{ItemData.name}</p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>{ItemData.categoryss}</p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>
                            {/* {priceFormate(ItemData.total_qty || 0)} */}
                            {!!ItemData.total_qty
                              ? priceFormate(formatCurrency(ItemData.total_qty))
                              : "$0.00"}
                          </p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>
                            {/* ${priceFormate(ItemData.total_price)} */}
                            {!!ItemData?.total_price
                              ? priceFormate(
                                  formatCurrency(ItemData?.total_price)
                                )
                              : "$0.00"}
                          </p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>
                            {/* ${priceFormate(ItemData.adjust_price)} */}
                            {!!ItemData.adjust_price
                              ? priceFormate(
                                  formatCurrency(ItemData.adjust_price)
                                )
                              : "$0.00"}
                          </p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>
                            {/* ${priceFormate(ItemData.discount_amt)} */}
                            {!!ItemData.discount_amt
                              ? priceFormate(
                                  formatCurrency(ItemData.discount_amt)
                                )
                              : "$0.00"}
                          </p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>${priceFormate(ItemData.saletx)}</p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>${priceFormate(ItemData.othertx)}</p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>${priceFormate(ItemData.refund_amount)}</p>
                        </StyledTableCell>
                        <StyledTableCell>
                          <p>
                            {!!ItemData.discount_price
                              ? priceFormate(
                                  formatCurrency(ItemData.discount_price)
                                )
                              : "$0.00"}
                          </p>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </StyledTable>
              {showNoData && !allItemSalesData.length >= 1 && <NoDataFound />}
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ItemSalesDetails;
