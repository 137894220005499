import React from "react";
import ItemsCategories from "./ItemsCategories";

const MainItem = ({ hide, setCSVData, setCSVHeader, setCSVFileName }) => {
  return (
    <>
      <div className="q-order-main-page">
        <div className="box">
          <ItemsCategories
            hide={hide}
            setCSVData={setCSVData}
            setCSVHeader={setCSVHeader}
            setCSVFileName={setCSVFileName}
          />
        </div>
      </div>
    </>
  );
};

export default MainItem;
