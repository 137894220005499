import React from "react";
import { Grid } from "@mui/material";
import { RevenueChart } from "./Charts/RevenueChart";
import { SalesCountChart } from "./Charts/SalesCountChart";
import { CustomerCountChart } from "./Charts/CustomerCountChart";
import { GrossProfitChart } from "./Charts/GrossProfitChart";
import { DiscountChart } from "./Charts/DiscountChart";
import { PercentageDiscountChart } from "./Charts/PercentageDiscountChart";
import { AvgItemSaleChart } from "./Charts/AvgItemSaleChart";
import { AvgSaleValueChart } from "./Charts/AvgSaleValueChart";
import { useInView } from "react-intersection-observer";

const NetSales = ({ activeType, merchantId, presentDate }) => {
  const options = {
    threshold: 0,
    // triggerOnce: true,
  };
  const [revenueChartRef, revenueChartInView] = useInView(options);
  const [salesCountChartRef, salesCountChartInView] = useInView(options);
  const [customerCountChartRef, customerCountChartInView] = useInView(options);
  const [grossProfitChartRef, grossProfitChartInView] = useInView(options);
  const [discountChartRef, discountChartInView] = useInView(options);
  const [percentageDiscountChartRef, percentageDiscountChartInView] =
    useInView(options);
  const [avgSaleValueChartRef, avgSaleValueChartInView] = useInView(options);
  const [avgItemSaleChartRef, avgItemSaleChartInView] = useInView(options);

  return (
    <>
      <Grid container spacing={3}>
        {/* Revenue Chart */}
        <RevenueChart
          activeType={activeType}
          merchantId={merchantId}
          presentDate={presentDate}
          revenueChartInView={revenueChartInView}
          revenueChartRef={revenueChartRef}
        />

        {/* Sales Count Chart */}
        <SalesCountChart
          activeType={activeType}
          merchantId={merchantId}
          presentDate={presentDate}
          salesCountChartInView={salesCountChartInView}
          salesCountChartRef={salesCountChartRef}
        />

        {/* Customer Count Chart */}
        <CustomerCountChart
          activeType={activeType}
          merchantId={merchantId}
          presentDate={presentDate}
          customerCountChartInView={customerCountChartInView}
          customerCountChartRef={customerCountChartRef}
        />

        {/* Gross Profit Chart */}
        <GrossProfitChart
          activeType={activeType}
          merchantId={merchantId}
          presentDate={presentDate}
          grossProfitChartInView={grossProfitChartInView}
          grossProfitChartRef={grossProfitChartRef}
        />

        {/* Avg Sale value chart */}
        <AvgSaleValueChart
          activeType={activeType}
          merchantId={merchantId}
          presentDate={presentDate}
          avgSaleValueChartRef={avgSaleValueChartRef}
          avgSaleValueChartInView={avgSaleValueChartInView}
        />

        {/* Avg items per sale chart */}
        <AvgItemSaleChart
          activeType={activeType}
          merchantId={merchantId}
          presentDate={presentDate}
          avgItemSaleChartInView={avgItemSaleChartInView}
          avgItemSaleChartRef={avgItemSaleChartRef}
        />

        {/* Discounted Chart */}
        <DiscountChart
          activeType={activeType}
          merchantId={merchantId}
          presentDate={presentDate}
          discountChartInView={discountChartInView}
          discountChartRef={discountChartRef}
        />

        {/* Percentage Discounted Chart */}
        <PercentageDiscountChart
          activeType={activeType}
          merchantId={merchantId}
          presentDate={presentDate}
          percentageDiscountChartInView={percentageDiscountChartInView}
          percentageDiscountChartRef={percentageDiscountChartRef}
        />
      </Grid>
    </>
  );
};

export default NetSales;
