import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchNewItemCreatedBetweenData } from "../../../Redux/features/Reports/NewItemCreatedBetweenSlice/NewItemCreatedBetweenSlice";
import { useAuthDetails } from "../../../Common/cookiesHelper";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@mui/material";
import { priceFormate } from "../../../hooks/priceFormate";
import { SortTableItemsHelperFun } from "../../../helperFunctions/sortTable";
import sortIcon from "../../../Assests/Category/SortingW.svg";
import PasswordShow from "../../../Common/passwordShow";
import { SkeletonTable } from "../../../reuseableComponents/SkeletonTable";
import NoDataFound from "../../../reuseableComponents/NoDataFound";
import { format } from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from 'react-loading-skeleton';

const StyledTable = styled(Table)(({ theme }) => ({
  padding: 2, // Adjust padding as needed
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#253338",
    color: theme.palette.common.white,
    fontFamily: "CircularSTDMedium !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
  [`&.${tableCellClasses.table}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {},
  "& td, & th": {
    // border: "none",
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(224, 224, 224, 1)',
  },
}));


const NewItemCreatedBetweenList = ({setProductListData,productData,scrollForProduct,hasMore, loading}) => {
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    const date = new Date(`${year}-${month}-${day}`);

    return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
  };
  const renderLoader = () => {
    return (
      <table>
        <tbody>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row) => (
              <tr key={row} style={{background: 'rgba(0, 0, 0, 0.04)'}} >
                {["", "", "", ""].map((col,index) => (
                  <td key={index}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const [sortOrder, setSortOrder] = useState("asc"); // "asc" for ascending, "desc" for descending
  const sortByItemName = (type, name) => {
    if(productData.length>0){
      const { sortedItems, newOrder } = SortTableItemsHelperFun(
        productData,
        type,
        name,
        sortOrder
      );
      setSortOrder(newOrder);
      setProductListData(sortedItems)
    }

  };

  useEffect(() => {
    // setallNewItemData(productData);
    setProductListData(productData)
  }, [productData]);
  
  return (
    <>
      <Grid container className="box_shadow_div">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              
              <InfiniteScroll
                dataLength={productData.length} // This tracks the current data length
                next={scrollForProduct} // This triggers the function to fetch more data
                hasMore={hasMore} // Determines if more data needs to be fetched
                loader={
                  productData.length > 0 && loading == true ? ( // Show loader only if data exists
                    <div className="custom-table">{renderLoader()}</div>
                  ) : null
                }
              >
                <TableContainer>
                  <StyledTable
                    sx={{ minWidth: 500 }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("date", "created_on")}
                        >
                          <p className="whitespace-nowrap">Date</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("str", "category")}
                        >
                          <p className="whitespace-nowrap">Category</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("str", "item_name")}
                        >
                          <p className="whitespace-nowrap">Item Name</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("num", "price")}
                        >
                          <p className="whitespace-nowrap">Price</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                    </TableHead>
                    <TableBody>
                    {loading && productData.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5}>
                          <div className="custom-table">{renderLoader()}</div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : productData.length > 0 ? (
                        productData?.map((ItemData, index) => (
                          <StyledTableRow key={index}>
                          <StyledTableCell>
                            <p >
                            {ItemData.created_on.split("-")[1] +
                                "/" +
                                ItemData.created_on.split("-")[0] +
                                "/" +
                                ItemData.created_on.split("-")[2]}
                              {/* {ItemData.created_on
                                .split("-")
                                .reverse()
                                .join("/")} */}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell>
                            <p>{ItemData.category ? ItemData.category.charAt(0).toUpperCase() + ItemData.category.slice(1) : ''}</p>
                          </StyledTableCell>
                          <StyledTableCell>
                            <p>{ItemData.item_name ? ItemData.item_name.charAt(0).toUpperCase() + ItemData.item_name.slice(1) : ''}</p>
                          </StyledTableCell>
                          <StyledTableCell>
                          <p>${priceFormate(ItemData.price ?? "0.00")}</p>
                          </StyledTableCell>
                        </StyledTableRow>
                        ))
                      ) : (
                        /* Show "No Data Found" when no data is available and not loading */
                        !loading && productData.length === 0 && (
                          <StyledTableRow>
                            <StyledTableCell colSpan={5}>
                              <div className="inv-no-record">
                                <NoDataFound message="No Data Found" />
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      )}
                    </TableBody>
                    
                  </StyledTable>
                  
                </TableContainer>
              </InfiniteScroll>
           
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
    </>
  );
};

export default NewItemCreatedBetweenList;
