import React, { useEffect, useState } from "react";
import { fetchPaymentMethodReportData } from "../../../Redux/features/PaymentMethodReport/PaymentMethodSlice";

import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@mui/material";
import { priceFormate } from "../../../hooks/priceFormate";
import PasswordShow from "../../../Common/passwordShow";
import { getAuthInvalidMessage } from "../../../Redux/features/Authentication/loginSlice";
import { SkeletonTable } from "../../../reuseableComponents/SkeletonTable";
import NoDataFound from "../../../reuseableComponents/NoDataFound";
import CommonTable from "../../../CommonComponents/CommonTable";
import { SortTableItemsHelperFun } from "../../../helperFunctions/SortTableItemsHelperFun";
import { formatCurrency } from "../../../Constants/utils";
import sortIcon from "../../../Assests/Category/SortingW.svg";
const StyledTable = styled(Table)(({ theme }) => ({
  padding: 2, // Adjust padding as needed
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#253338",
    color: theme.palette.common.white,
    fontFamily: "CircularSTDMedium !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
  [`&.${tableCellClasses.table}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {},
  "&:last-child td, &:last-child th": {
    backgroundColor: "#F5F5F5",
  },
  "& td, & th": {
    border: "none",
  },
}));

const PaymentMethodList = ({
  data,
  setCSVData,
  setCSVHeader,
  setCSVFileName,
}) => {
  const dispatch = useDispatch();
  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  const [sortOrder, setSortOrder] = useState("asc");
  const [paymentReport, setpaymentReport] = useState([]);
  const [total, setTotal] = useState(0);
  console.log("paymentReport", paymentReport);
  const paymentReportDataState = useSelector(
    (state) => state.paymentDetailReport
  );

  useEffect(() => {
    fetchPaymentReportData();
  }, [data]);

  const fetchPaymentReportData = async () => {
    try {
      let newData = { ...data };
      if (newData?.merchant_id) {
        await dispatch(fetchPaymentMethodReportData(newData)).unwrap();
      }
    } catch (error) {
      if (error.status == 401 || error.response.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error.status == "Network Error") {
        getNetworkError();
      }
    }
  };

  useEffect(() => {
    if (
      !paymentReportDataState.loading &&
      paymentReportDataState.paymentMethodData
    ) {
      const arr = Object.keys(paymentReportDataState?.paymentMethodData).map(
        (key) => ({
          card_type: key,
          amt: paymentReportDataState?.paymentMethodData[key],
        })
      );
      // console.log("arr: ", arr);
      setpaymentReport(arr);
      const gotTotal = arr.reduce((acc, item) => {
        // console.log("item.amt", item.amt, "acc", acc);
        return acc + (isNaN(parseFloat(item.amt)) ? 0 : parseFloat(item.amt));
      }, 0);
      setTotal(gotTotal);
      console.log("gotTotal", gotTotal);

      // filtering out data whose Amount is 0 or less, and formatting the amount in priceFormate function
      const data = arr
        .filter((item) => item.amt > 0)
        ?.map((item) => ({
          ...item,
          amt: `$${priceFormate(parseFloat(item.amt).toFixed(2))}`,
        }));

      if (gotTotal > 0 && data.length > 0) {
        const headers = [
          { label: "Payment Type", key: "card_type" },
          { label: "Amount", key: "amt" },
        ];
        // console.log("headers: ", headers);
        setCSVHeader(headers);

        const csvData = [
          ...data,
          {
            card_type: "Grand Total",
            amt: `$${priceFormate(parseFloat(gotTotal).toFixed(2))}`,
          },
        ];
        // console.log("csv data: ", csvData);
        setCSVData(csvData);
        setCSVFileName("payment_method_details_report");
      }
    }
  }, [
    paymentReportDataState,
    paymentReportDataState.loading,
    paymentReportDataState.paymentMethodData,
  ]);

  const tableRow = [
    { type: "str", name: "card_type", label: "Payment Type" },
    { type: "num", name: "amt", label: "Amount" },
  ];

  const sortByItemName = (type, name) => {
    const { sortedItems, newOrder, sortIcon } = SortTableItemsHelperFun(
      paymentReport,
      type,
      name,
      sortOrder
    );
    setpaymentReport(sortedItems);
    setSortOrder(newOrder);
  };

  return (
    <>
      {/* <Grid container style={{ marginBottom: 0 }} className="box_shadow_div">
        <Grid item xs={12}>
          {paymentReportDataState.loading ? (
            <SkeletonTable columns={["Payment Type", "Amount"]} />
          ) : (
            <TableContainer>
              <StyledTable sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <StyledTableCell>Payment Type</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                </TableHead>
                <TableBody>
                  {total
                    ? paymentReport.map((paymentData, index) => {
                        if (paymentData.amt > 0) {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                <p className="report-title">
                                  {paymentData.card_type === ""
                                    ? "N/A"
                                    : paymentData.card_type}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell>
                                <p className="report-title">
                                  {typeof paymentData.amt != ""
                                    ? `$${priceFormate(
                                        Number(paymentData.amt).toFixed(2)
                                      )}`
                                    : "N/A"}
                                </p>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        } else {
                          return null;
                        }
                      })
                    : ""}
                  {total > 0 && (
                    <>
                      <StyledTableCell className="trBG_Color">
                        <div className="q-category-bottom-report-listing">
                          <div>
                            <p className="report-sort totalReport">
                              Grand Total
                            </p>
                          </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell className="trBG_Color">
                        <div className="q-category-bottom-report-listing">
                          <div>
                            <p className="report-title totalReport">
                              ${priceFormate(total.toFixed(2))}
                            </p>
                          </div>
                        </div>
                      </StyledTableCell>
                    </>
                  )}
                </TableBody>
              </StyledTable>
              {!total && <NoDataFound />}
            </TableContainer>
          )}
        </Grid>
      </Grid> */}

      <CommonTable
        isShow={true}
        loading={paymentReportDataState?.loading}
        childHeader={tableRow.map((col, index) => (
          <td key={index}>
            <button
              className="flex items-center"
              onClick={() => sortByItemName(col.type, col.name)}
              disabled={col.name === ""}
            >
              <p>{col.label}</p>
              <img src={sortIcon} alt="" className="pl-1" />
            </button>
          </td>
        ))}
        childrenBody={(Array.isArray(paymentReport) && total > 0
          ? paymentReport
          : []
        )?.map((paymentData, index) =>
          +paymentData?.amt > 0 ? (
            <tr key={paymentData?.id}>
              <td>
                <p>
                  {paymentData?.card_type === ""
                    ? "N/A"
                    : paymentData?.card_type}
                </p>
              </td>
              <td>
                {formatCurrency(parseFloat(paymentData?.amt || 0).toFixed(2))}
              </td>
            </tr>
          ) : null
        )}
        childFooter={
          total > 0 && (
            <tr>
              <td>Grand Total</td>
              <td>{formatCurrency(parseFloat(total || 0).toFixed(2))}</td>
            </tr>
          )
        }
      />
    </>
  );
};

export default PaymentMethodList;
