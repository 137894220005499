import { useState, useRef, useEffect } from "react";
import axios from "axios";

import Validation from "../../../Constants/Validation";
import { BASE_URL, UPDATE_PERMISSION } from "../../../Constants/Config";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import { ToastifyAlert } from "../../../CommonComponents/ToastifyAlert";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { set } from "date-fns";

const defaultPermissions = {
  cashier: [
    "RG",
    "O",
    "GC",
    "CU",
    "RE",
    "CT",
    "CR",
    "CL",
    "CD",
    "FO",
    "CE",
    "GI",
    "CI",
    "CB",
    "LT",
    "UP",
    "AU",
    "TC",
  ],
  driver: ["TC", "CD"],
  time_clock_only: ["TC"],
};

const EditPermissionLogic = ({ employeedata, setVisible }) => {
  // console.log(employeedata)
  const { isNumber, validateRadioBtn } = Validation();
  const [submitmessage, setsubmitmessage] = useState("");
  const [open, setOpen] = useState(false); // Initialize with false
  const [permissionList, setpermissionList] = useState([]);
  // const Navigate = useNavigate();
  const scrollRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  let AuthDecryptDataDashBoardJSONFormat = LoginGetDashBoardRecordJson;
  const merchant_id = AuthDecryptDataDashBoardJSONFormat?.data?.merchant_id;

  const employeeListDataState = useSelector((state) => state.employeelistData);

  const [values, setValues] = useState({
    role: "",
    break_allowed: "",
    break_time: "",
    paid_breaks: "",
    permissions: "",
    errors: {
      role: "",
      break_allowed: "",
      break_time: "",
      paid_breaks: "",
      permissions: "",
    },
  });

  useEffect(() => {
    let arr = employeedata?.permissions?.split(",");
    setValues((prevValues) => ({
      ...prevValues,
      role: employeedata && employeedata.role ? employeedata.role : "manager",
      break_allowed:
        employeedata && employeedata.break_allowed
          ? employeedata.break_allowed
          : "",
      break_time:
        employeedata && employeedata.break_time ? employeedata.break_time : "",
      paid_breaks:
        employeedata && employeedata.paid_breaks
          ? employeedata.paid_breaks
          : "",
      permissions:
        employeedata && employeedata.permissions
          ? employeedata.permissions
          : "",
      // permissions:
      //   employeedata && employeedata.permissions
      //     ? Object.values(permissionList)
      //     .map(item => item.filter(i => arr.includes(i.permission_code)).map(l => l.permission_code))
      //     // .map(item => item.filter(i => arr.includes(i.id)).map(l => l.permission_code))
      //     .flat().toString()
      //     : "",
    }));
    // console.log(
    //   "employeedata?.permissions",
    //   employeedata?.permissions?.split(","),
    //   Object.values(permissionList).map((item) =>
    //     item.filter((arr) => employeedata?.permissions?.split(",")?.includes(arr.id)).map((item) => item.permission_code)
    //   )
    // );
    // console.log(
    //   "fdasdfsfsdfsfdfsdf",
    //   Object.values(permissionList)
    //     .filter((item) => arr && arr?.includes(item.id))
    //     .map((item) => item.permission_code)
    // );
    // const filteredPermissions = Object.values(permissionList).map((item)=> item.filter((i)=> arr.includes(i.id)).map((l)=>l.permission_code))
    const filteredPermissions = Object.values(permissionList)
      .map((item) =>
        item.filter((i) => arr?.includes(i.id)).map((l) => l.permission_code)
      )
      .flat()
      .toString();

    console.log("filteredPermissions", filteredPermissions);
  }, [employeedata, employeedata?.permissions]);

  const handleToggleCategory = (category, checked) => {
    console.log("category", category);
    const categoryPermissions = category.map(
      (subItem) => subItem.permission_code
    );

    setValues((prevValues) => {
      const currentPermissions = prevValues.permissions
        .split(",")
        .filter(Boolean); // Split the current permissions into an array

      if (checked) {
        // If checked, add all category permissions
        return {
          ...prevValues,
          permissions: Array.from(
            new Set([...currentPermissions, ...categoryPermissions])
          ).toString(), // Remove duplicates and convert back to string
        };
      } else {
        // If unchecked, remove all category permissions
        return {
          ...prevValues,
          permissions: Array.from(
            new Set(
              currentPermissions.filter(
                (id) => !categoryPermissions?.includes(id)
              )
            )
          ).toString(), // Remove specified category permissions
        };
      }
    });
  };

  const handleEditEmpPermissionInput = async (event) => {
    console.log("handleEditEmpPermissionInput event", event.target.name);
    let { errors } = values;
    const fieldName = event.target.name; //role
    const fieldValue = event.target.value; //manager

    switch (fieldName) {
      case "break_allowed":
        await isNumber(fieldValue, fieldName, errors);
        break;
      case "break_time":
        await isNumber(fieldValue, fieldName, errors);
        break;
      case "paid_breaks":
        await isNumber(fieldValue, fieldName, errors);
        break;
      case "role":
        // manager
        // cashier
        // driver
        // time_clock_only

        // console.log(
        //   "employeeListDataState.permissionData: ",
        //   employeeListDataState.permissionData
        // );

        // on changing of the roles.. set the default permissions based on role to checked..
        if (employeeListDataState.permissionData && fieldValue) {
          // value - manager
          // const allFields = []; // this will hold all the permission fields

          const allFields = Object.values(
            employeeListDataState.permissionData
          ).flat();
          console.log(
            "employeeListDataState.permissionData",
            employeeListDataState.permissionData
          );
          console.log("allFields", allFields);
          const selectedPermissions = allFields.filter((item) => {
            if (fieldValue === "manager") return item; // if manager then allow all items to be checked

            const permissions = defaultPermissions[fieldValue]; // allowing items based on role
            // console.log("permissions defaultPermissions",permissions)
            return permissions?.includes(item.permission_code);
          });

          // default permission ids based on the role
          const selectedPermissionsIds = selectedPermissions
            .map((item) => item.permission_code)
            .join(",");

          setValues({
            ...values,
            permissions: selectedPermissionsIds,
          });
        }

        // values.permissions => users default permissions
        await validateRadioBtn(fieldValue, errors);
        break;
      case "permission[]":
        let permissionsArray = values.permissions
          ? values.permissions.split(",")
          : [];
        if (permissionsArray?.includes(event.target.value)) {
          permissionsArray = permissionsArray.filter(
            (item) => item !== event.target.value
          );
        } else {
          permissionsArray.push(event.target.value);
        }
        const updatedPermissions = permissionsArray.join(",");
        setValues({
          ...values, // Spread the existing state
          permissions: updatedPermissions, // Update only the permissions property
        });
        break;
      default:
        break;
    }

    setValues((prevValues) => ({
      errors,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };

  const handleEditEmpPermission = async (e) => {
    // console.log('222');
    e.preventDefault();
    let { errors } = values;
    open && (await isNumber(values.break_allowed, "break_allowed", errors));
    open && (await isNumber(values.break_time, "break_time", errors));
    open && (await isNumber(values.paid_breaks, "paid_breaks", errors));
    await validateRadioBtn(values.role, errors);
    if (values.break_allowed >= values.paid_breaks) {
      if (
        errors.break_allowed === "" &&
        errors.break_time === "" &&
        errors.paid_breaks === "" &&
        errors.role === ""
      ) {
        const data = {
          merchant_id: merchant_id,
          // "admin_id":"MAL0100CA",
          admin_id: "",
          employee_id: employeedata.id,
          role: values.role,
          break_allowed: !open ? "0" : values.break_allowed,
          break_time: !open ? "0" : values.break_time,
          paid_breaks: !open ? "0" : values.paid_breaks,
          permissions: values.permissions,
          token_id: userTypeData?.token_id,
          login_type: userTypeData?.login_type,
        };
        console.log(data);
        try {
          const response = await axios.post(
            BASE_URL + UPDATE_PERMISSION,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userTypeData?.token}`,
              },
            }
          );

          if (response.data.status === true) {
            // alert(response.data.message)
            ToastifyAlert("Updated Successfully", "success");
            setVisible("EmployeeList");
            Navigate("/store-settings/addemployee");
          } else {
            // alert(response.data.message)
            ToastifyAlert(response?.data?.message, "warn");
            //   await handleScrollClick()
            setsubmitmessage(response.data.message);
            setShowModal(true);
          }
        } catch (error) {
          // console.log('33 catch err');
          return new Error(error);
        }
      }

      setValues((prevState) => ({
        ...prevState,
        errors,
      }));
    } else {
      alert(
        "Paid Breaks should be not more than the number of breaks allowed per day"
      );
    }
  };

  return {
    handleEditEmpPermissionInput,
    handleToggleCategory,
    values,
    handleEditEmpPermission,
    submitmessage,
    setsubmitmessage,
    showModal,
    setShowModal,
    scrollRef,
    open,
    setOpen,
    setValues,
    setpermissionList,
    permissionList,
  };
};

export default EditPermissionLogic;
