import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import SpikeCharts from "../SpikeCharts";
import { useSelector } from "react-redux";
import {
  removeCurrencySign,
  getDefaultDateRange,
  getXAxisData,
  getYAxisTicks,
  onNext,
  onPrevious,
  getStartDateAndEndDate,
  getPercentValue,
  getLastRangeStartDate,
} from "../../../Constants/utils";
import { useDispatch } from "react-redux";
import PasswordShow from "../../../Common/passwordShow";
import { getCustomerCount } from "../../../Redux/features/Dashboard/Charts/customerCountSlice";
import { priceFormate } from "../../../hooks/priceFormate";

export const CustomerCountChart = ({
  merchantId,
  activeType,
  presentDate,
  customerCountChartInView,
  customerCountChartRef,
}) => {
  const { handleCoockieExpire, getUnAutherisedTokenMessage } = PasswordShow();
  const dispatch = useDispatch();
  // const [presentDate, setPresentDate] = useState(new Date());
  const [xAxisDates, setXAxisDates] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [customerCountChartState, setCustomerCountChartState] = useState(false);
  const customerCountChart = useSelector((state) => state.customerCountChart);

  useEffect(() => {
    if (customerCountChartInView && !customerCountChartState) {
      setCustomerCountChartState(() => true);
    }
  }, [customerCountChartInView, customerCountChartState]);

  const customerCountChartData = useMemo(() => {
    const bool =
      customerCountChart &&
      customerCountChart?.customerCountData &&
      customerCountChart?.customerCountData?.length > 3;

    if (bool) {
      const getValue = (data, index) => {
        const value =
          data &&
          data?.customerCountData &&
          data?.customerCountData.length > 2 &&
          data?.customerCountData[data?.customerCountData?.length - index];
        return value?.total_customer && parseFloat(value?.total_customer)
          ? parseFloat(value?.total_customer).toFixed(2)
          : 0;
      };

      // for Percentage variance --------------------------------------
      const oldValue = getValue(customerCountChart, 2);
      const newValue = getValue(customerCountChart, 1);
      const percent = getPercentValue(oldValue, newValue);

      // for X Axis data ----------------------------------------------
      const dataOfXAxis = customerCountChart?.customerCountData?.map(
        (item, index) => ({
          name: xAxisDates[index],
          uv:
            // item?.total_customer && parseFloat(item?.total_customer) > 0
            //   ? parseFloat(item?.total_customer)
            //   : 0,
            item.total_customer
              ? parseFloat(parseFloat(item.total_customer).toFixed(2))
              : 0,
        })
      );

      // for Y Axis Data ----------------------------------------------
      const getMinMaxValues = (data) => {
        const maxUv = Math.max(...data?.map((item) => item?.uv));
        const minUv = Math.min(...data?.map((item) => item?.uv));

        return {
          maxUv,
          minUv,
        };
      };

      const minAndMaxValue =
        dataOfXAxis && dataOfXAxis?.length > 0 && getMinMaxValues(dataOfXAxis);

      const temp = getYAxisTicks(minAndMaxValue?.maxUv || 0);
      const yAxisOptions = temp?.map((val) => parseFloat(val));

      return {
        percent: parseFloat(percent)?.toFixed(2),
        xAxisData: dataOfXAxis,
        minValue: minAndMaxValue?.minUv,
        maxValue: minAndMaxValue?.maxUv,
        yAxisOptions,
        type: "number",
      };
    } else {
      return {
        percent: 0,
        xAxisData: [],
        minValue: 0,
        maxValue: 0,
        yAxisOptions: [],
        type: "number",
      };
    }
  }, [customerCountChart, xAxisDates]);

  // setting date range
  useEffect(() => {
    const dates = getDefaultDateRange(activeType, presentDate);
    setDateRange(dates);
    setCustomerCountChartState(() => false);

    if (!dates || !dates.date_range) return;
    const data = getXAxisData(activeType, dates?.date_range);
    setXAxisDates(data);
  }, [activeType, presentDate]);

  useEffect(() => {
    const fetchCustomerCount = async () => {
      try {
        if (dateRange && dateRange?.date_range) {
          const { endDate } = getStartDateAndEndDate(dateRange?.date_range);

          const data = {
            merchant_id: merchantId,
            // start_date: startDate,
            start_date: getLastRangeStartDate(dateRange?.date_range),
            end_date: endDate,
            date_range: dateRange?.date_range,
          };
          await dispatch(getCustomerCount(data)).unwrap();
        }
      } catch (error) {
        if (error?.status == 401 || error?.response?.status === 401) {
          getUnAutherisedTokenMessage();
          handleCoockieExpire();
        } else if (error?.status == "Network Error") {
          // getNetworkError();
        }
      }
    };

    if (
      dateRange &&
      dateRange?.date_range &&
      merchantId &&
      customerCountChartState
    ) {
      fetchCustomerCount();
    }
  }, [dateRange, merchantId, customerCountChartState]);

  // generate X Axis dates dataset
  // useEffect(() => {
  //   if (!dateRange || !dateRange.date_range) return;

  //   const data = getXAxisData(activeType, dateRange?.date_range);
  //   setXAxisDates(data);
  // }, [activeType, dateRange]);

  const totalValue = useMemo(() => {
    const amt =
      !isNaN(customerCountChart?.totalCustomerCount) &&
      parseFloat(customerCountChart?.totalCustomerCount)
        ? parseFloat(customerCountChart?.totalCustomerCount)
        : 0;
    const a = priceFormate(parseFloat(amt));
    return a;
  }, [customerCountChart.totalCustomerCount]);

  return (
    <Grid item xs={12} md={6} lg={6} ref={customerCountChartRef}>
      <SpikeCharts
        title={"Customer Count"}
        growth={customerCountChartData?.percent}
        mainOutlet={totalValue}
        amount={totalValue}
        activeType={activeType}
        xAxisData={customerCountChartData?.xAxisData}
        maxValue={customerCountChartData?.maxValue}
        minValue={customerCountChartData?.minValue}
        yAxisOptions={customerCountChartData?.yAxisOptions}
        type={customerCountChartData?.type}
        formatFunction={removeCurrencySign}
        // prevDataFunction={previousDateRange}
        // nextDataFunction={nextDateRange}
        loading={
          (customerCountChart?.loading || customerCountChart?.isCancel) ?? false
        }
        presentDate={presentDate}
      />
    </Grid>
  );
};
